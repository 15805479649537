:root {
  --lighterGray: #e7eff7;
  --lightGray: #e2e9f0;
  --gray: #c9dae8;
  --darkGray: #727679;
}

.App {
  font-family: 'Source Sans Pro', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 480px;
}

.Header {
  width: 100%;
  text-align: center;
  background-color: white;
  font-size: 36px;
  height: 48px;
  padding: 6px;
  font-weight: 500;
}

.DayRows {
  width: 100%;
  padding: 0 0 12px;
}

.DayRow {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid var(--lightGray);
}

.DayLabel {
  width: 92px;
  text-align: center;
  padding: 12px 0;
  background-color: var(--lighterGray);
}

.DayLabelMonth {
  text-align: center;
  font-size: 9px;
  height: 10px;
  font-weight: 600;
}

.DayLabelNumber {
  font-size: 24px;
  padding: 3px 0;
}

.DayLabelDay {
  font-size: 10px;
}

.DayCounter {
  flex: 1;
  padding: 18px 30px;
  display: flex;
}

.CounterButton {
  width: 42px;
  height: 42px;
}

.CounterEmptyText {
  line-height: 44px;
  font-size: 14px;
  text-align: center;
  margin: 0 8px;
  flex-grow: 1;
}

.CounterText {
  line-height: 44px;
  font-size: 30px;
  text-align: center;
  margin: 0 8px;
  flex-grow: 1;
}

.WeekSummary {
  text-align: center;
  padding: 4px 0;
  background-color: var(--lightGray);
}

.MonthSummary {
  text-align: center;
  padding: 4px 0;
  background-color: var(--gray);
}

.SummaryLabel {
  color: var(--darkGray);
}

.Footer {
  color: var(--darkGray);
  padding: 4px 0 32px;
  text-align: center;
}

.About {
  padding: 4px 28px;
  line-height: 140%;
}

.Footer a {
  color: inherit;
  padding: 10px 0;
  text-decoration: none;
  display: block;
}
